<template>
  <div :class="{'modal-card':inModal}" style="width: auto">
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <span class="is-size-3">Topics:</span>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-button
                :icon-right="$tc('icons.create')"
                type="is-primary"
                @click="startCreateTopic"
            >
              Add Topic
            </b-button>
          </b-field>

        </div>
      </div>
    </div>
    <section :class="{'modal-card-body':inModal}">
      <b-table
          v-if="isLoaded"
          ref="table"
          :bordered="false"
          :data="topics"
          :hoverable="true"
          :striped="true"
          class="is-clickable"
          @click="startEditTopic"
      >
        <template #empty>
          <div class="has-text-centered">No Topics</div>
        </template>
        <b-table-column v-slot="props" field="name" label="Topic"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.name }}</span>
          <b-field v-else>
            <b-input v-model="props.row.name"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" field="min_percent" label="Min %"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.min_percent }}</span>
          <b-field v-else>
            <b-input v-model="props.row.min_percent"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" field="max_percent" label="Max %"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.max_percent }}</span>
          <b-field v-else>
            <b-input v-model="props.row.max_percent"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" field="min_hours" label="Min Hours"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.min_hours }}</span>
          <b-field v-else>
            <b-input v-model="props.row.min_hours"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" field="max_hours" label="Max Hours"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.max_hours }}</span>
          <b-field v-else>
            <b-input v-model="props.row.max_hours"></b-input>
          </b-field>
        </b-table-column>

        <b-table-column v-slot="props" field="order" label="Order"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.order }}</span>
          <b-field v-else>
            <b-input v-model="props.row.order" type="number"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" field="reference" label="Topic Ref"
                        sortable><span v-if="row_edit_id!==props.row.id">{{ props.row.reference }}</span>
          <b-field v-else>
            <b-input v-model="props.row.reference"></b-input>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" centered>
          <div v-on:click.stop>
            <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-icon
                    :icon="$tc('icons.more')"/>
              </template>
              <b-dropdown-item v-if="row_edit_id!==props.row.id"
                               aria-role="listitem"
                               @click="manageUnits(props.row)">Manage Units
              </b-dropdown-item>
              <b-dropdown-item v-if="row_edit_id!==props.row.id"
                               aria-role="listitem"
                               @click="startDeleteTopic(props.row)">Delete
              </b-dropdown-item>
              <b-dropdown-item v-if="row_edit_id===props.row.id"
                               aria-role="listitem"
                               @click="cancelEditTopic(props.row)">Cancel Edit
              </b-dropdown-item>
              <b-dropdown-item v-if="row_edit_id===props.row.id"
                               aria-role="listitem"
                               @click="saveEditTopic(props.row)">Save
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
      </b-table>
      <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
    </section>

  </div>
</template>

<script>
import Topic from "@/models/Topic";
import TopicCreateForm from "@/components/courses/TopicCreateForm";

export default {
  name: "TopicPanel",
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      row_hold: null,
      row_edit_id: null
    }
  },
  computed: {
    topics() {
      return Topic.query().where('course_scope_id', this.course_id).orderBy('order').get()
    }
  },
  methods: {
    manageUnits(topic) {
      this.$emit('manage-units', topic)
    },
    startCreateTopic() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          course_scope_id: this.course_id
        },
        component: TopicCreateForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    startDeleteTopic(topic) {
      this.$buefy.dialog.confirm({
        title: `Deleting topic`,
        confirmText: `Delete topic`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this topic?`,
        onConfirm: () =>
            Topic.Delete(topic.id)
                .then(() => {
                  this.$buefy.snackbar.open(`topic deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startEditTopic(row) {
      this.row_hold = JSON.parse(JSON.stringify(row));
      this.row_edit_id = row.id;
    },
    cancelEditTopic() {
      Topic.update({
        data:this.row_hold
      })
      this.row_edit_id = null
    },
    saveEditTopic(topic) {
      this.isLoading = true
      Topic.Update(topic).then(() => {
        this.isLoading = false
        this.row_edit_id = null;

        this.$buefy.snackbar.open('Topic Updated!')
      }).catch(err => {
        this.isLoading = false
        this.handleError(err)
      })
    }
  },

  mounted() {
    this.isLoading = true

    Promise.all([Topic.FetchAll({
      page: 1,
      limit: 999
    }, {course_scope_id: this.course_id})],).then(() => {

      this.isLoading = false
      this.isLoaded = true
    })
    if (this.edit) {
      this.isLoading = false
      this.courseObject = this.course
    }
  },
  props: {

    course_id: {
      type: Number,
      required: true
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

