<template>
  <div :class="{'modal-card':inModal}">


    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <span class="is-size-3">Active Topic:</span>
        </div>
        <div class="level-item">
          <b-field>
            <b-select placeholder="Topic" v-model="topicSelection">
              <option v-for="topic in topics" :key="topic.id" :value="topic.id">{{
                  topic.name
                }}
              </option>
            </b-select>
          </b-field>

        </div>
      </div>
      <!-- Right side -->

    </div>
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <span class="is-size-3">Units:</span>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div class="level-item">
          <b-field>
            <b-button
                type="is-primary"
                :disabled="activeTopicId===null"
                @click="startCreateUnit"
                :icon-right="$tc('icons.create')"
            >
              Add Unit
            </b-button>
          </b-field>

        </div>
      </div>
    </div>
    <div v-if="activeTopic">
      <UnitItem @edit="editUnit(unit)" v-for="unit in units" :key="unit.id" :unit="unit"></UnitItem>
    </div>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>

  </div>
</template>

<script>
import Topic from "@/models/Topic";
import UnitItem from "@/components/courses/UnitItem";
import UnitCreateForm from "@/components/courses/UnitCreateForm";
import Unit from "@/models/Unit";

export default {
  name: "UnitPanel",
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      row_hold: null,
      row_edit_id: null
    }
  },
  components: {
    UnitItem
  },
  computed: {
    topicSelection: {
      get() {
        return this.activeTopicId
      }, set(newVal) {
        this.$emit('manage-units', {id: newVal})
        this.isLoading = true
        Topic.FetchById(newVal, ['units', 'units.objectives']).then(() => {
          this.isLoading = false
        }).catch(err => {
          this.handleError(err)
          this.isLoading = false
        })
      }
    },
    units() {
      return Unit.query().where('course_scope_topic_id', this.topicSelection).with('objectives').orderBy('order').get()
    },
    activeTopic() {
      return Topic.query().whereId(this.topicSelection).with('units').with('units.objectives').first()
    },
    topics() {
      return Topic.query().where('course_scope_id', this.course_id).orderBy('order').get()
    }
  },
  methods: {
    manageUnits(topic) {
      this.$emit('manage-units', topic)
    },
    editUnit(unit) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          unit: unit,
          inModal: true,
          edit: true,
          course_scope_topic_id: this.activeTopicId
        },
        component: UnitCreateForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    startCreateUnit() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
          course_scope_topic_id: this.activeTopicId
        },
        component: UnitCreateForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    startDeleteTopic(topic) {
      this.$buefy.dialog.confirm({
        title: `Deleting topic`,
        confirmText: `Delete topic`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this topic?`,
        onConfirm: () =>
            Topic.Delete(topic.id)
                .then(() => {
                  this.$buefy.snackbar.open(`topic deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startEditTopic(row) {
      this.row_hold = JSON.parse(JSON.stringify(row));
      this.row_edit_id = row.id;
    },

    saveEditTopic(topic) {
      this.isLoading = true
      Topic.Update(topic).then(() => {
        this.isLoading = false
        this.row_edit_id = null;

        this.$buefy.snackbar.open('Topic Updated!')
      }).catch(err => {
        this.isLoading = false
        this.handleError(err)
      })
    }
  },

  mounted() {
    this.isLoading = true
    let promises = [[Topic.FetchAll({
      page: 1,
      limit: 999
    }, {course_scope_id: this.course_id})]]
    if (this.activeTopicId !== null) {
      promises.push(Topic.FetchById(this.activeTopicId, ['units', 'units.objectives']))
    }
    Promise.all(promises).then(() => {

      this.isLoading = false
      this.isLoaded = true
    })
    if (this.edit) {
      this.isLoading = false
      this.courseObject = this.course
    }
  },
  props: {
    activeTopicId: {
      type: Number,
      default() {
        return null
      }
    },
    course_id: {
      type: Number,
      required: true
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

