<!--<style scoped>-->
<!--.right-field {-->
<!--  margin-bottom: unset;-->
<!--  margin-right: 0.75rem;-->
<!--}-->
<!--</style>-->

<template>
  <div :class="{'modal-card':inModal}">
    <header v-if="inModal" class="modal-card-head" style="max-width: 100%">
      <p class="modal-card-title">Select Objective for {{ unitName }}</p>

    </header>
    <section :class="{'modal-card-body':inModal}">
      <section class="section pt-4">
        <b-field grouped group-multiline>
          <b-field expanded class="right-field" v-if="!!$store.getters['entities/user-permissions/find']('create objectives')">
            <b-button expanded
                      type="is-primary"
                      @click="startCreatingObjective"
                      :icon-right="$tc('icons.create')"
            >
              Create Objective
            </b-button>
          </b-field>

        </b-field>
        <div>
          <b-field grouped>
            <b-field expanded label="Search">
              <b-input
                  v-debounce:300ms="getFilteredObjectives"
                  placeholder="Search"
                  :loading="loadingFilter"
                  v-model="searchHold"
              ></b-input>
            </b-field>
            <b-field label="Search Type">
              <b-switch v-model="searchType"
                        true-value="Reference"
                        false-value="Content">
                {{ searchType }}
              </b-switch>
            </b-field>
          </b-field>
          <PerfectScrollbarWrapper>
          <b-table
              class="margin-top is-fullwidth"
              :data="objectives"
              :loading="loadingData"
              :striped="true"
              :hoverable="true"
              :bordered="true"
              :backend-sorting="true"
              :default-sort="['reference', 'asc']"
              @sort="onSort"
          >
            <template #empty>
              <div class="has-text-centered">No Objectives</div>
            </template>
            <b-table-column
                v-slot="props"
                label="ID"
                field="id"
                sortable
                width="40"
                numeric
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="reference"
                label="Objective Reference"
            >{{ props.row.reference }}
            </b-table-column>
            <b-table-column
                v-slot="props"

                field="name"
                label="Content"
            >
              <div class="inline-objective-content">
                <ckeditor disabled :editor="editor" :value="clipDescription(props.row.name)"
                          :config="editorConfig"></ckeditor>
              </div>
            </b-table-column>

            <b-table-column
                v-slot="props"
                narrowed
                centered
                width="100"
                label="Actions"
                cell-class="py-1"
                custom-key="actions"
            >
              <b-field grouped group-multiline>
                <p class="control">
                  <b-button @click="editObjective(props.row)" type="is-primary"
                  >View
                  </b-button
                  >
                </p>
                <p class="control">
                  <b-button @click="select(props.row)" type="is-primary"
                  >Select
                  </b-button
                  >
                </p>

              </b-field>
            </b-table-column>
          </b-table>
          </PerfectScrollbarWrapper>
          <b-pagination
              class="mt-4"
              :total="meta.total"
              :current="page"
              :range-before="2"
              :range-after="2"
              :per-page="limit"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
              v-on:change="setPage"
          ></b-pagination>
        </div>
      </section>

    </section>
    <footer class="modal-card-foot">
      <b-button type="is-danger" @click="$emit('close')">Cancel
      </b-button>

    </footer>
  </div>
</template>

<script>
import Objective from "@/models/Objective";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {mathEditorConfig} from "@/mathEditorConfig";
import clip from "text-clipper";
import ObjectiveForm from "@/components/objectives/ObjectiveForm";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "ObjectiveSearch",
  components: {PerfectScrollbarWrapper},
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...{plugins: mathEditorConfig.plugins, toolbar: []},
        ...{placeholder: "Objective body"},

      },
      searchType: 'Reference',
      search: '',
      loadingFilter: false,
      objectiveDataHold: null,
      meta: Object,
      page: 1,
      order_by: 'reference',
      order_direction: 'asc',
      limit: 20,
      loadingData: false,
      searchHold: null,
      objectives: [],
      loaded: false

    }
  },
  computed: {
    filters() {
      return {


        ...(this.search.length > 0 && this.searchType === 'Reference'
            ? {reference: this.search}
            : {}), ...(this.search.length > 0 && this.searchType === 'Content'
            ? {name: this.search}
            : {}),
      };
    },

  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  methods: {
    select(objective) {
      // this.$cookies.set(
      //     `objective_filters`,
      //     JSON.stringify({
      //       page: this.page,
      //       order_by: this.order_by,
      //       order_direction: this.order_direction,
      //       search: this.search,
      //       searchHold: this.searchHold,
      //       searchType: this.searchType
      //
      //     }),
      //     "1d"
      // );
      this.$emit('selected', objective)
      this.$emit('close')
    },
    editObjective(objective) {
      // console.log(objective)
      // this.$router.push(`/objectives/edit/${objective.id}`)
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          objective: objective
        },
        component: ObjectiveForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    startCreatingObjective() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: false,
        },
        component: ObjectiveForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "submit" : (objective) => {
           this.select(objective)
          }
        }
      });
    },
    setPage(pageNumber) {
      if (this.loaded) {
        this.loadingData = true;
        this.page = pageNumber;
        Objective.FetchAll(
            {
              page: this.page,
              limit: this.limit,
            },
            {
              ...this.filters, ...(this.order_by
                  ? {
                    order_by: this.order_by,
                  }
                  : {order_by: "created_at"}),
              ...(this.order_direction
                  ? {
                    order_direction: this.order_direction,
                  }
                  : {order_direction: "desc"}),
            }
            // , ['learning_stream']
        ).then(({response: {data: {meta}}, entities}) => {
              this.objectives = Object.prototype.hasOwnProperty.call(entities, 'objectives') ? entities.objectives : []
              this.meta = meta;
              this.loadingData = false;

            }
        );
      }
    }
    ,
    onSort(field, order) {

      this.order_by = field;
      this.order_direction = order;
      this.setPage(this.page);

    },
    getFilteredObjectives(text) {
      this.search = text;
    },
    clipDescription(text) {
      return clip(text, 50, {
        html: true,
      });
    },


  },
  mounted() {
    // if (this.$cookies.get('objective_filters')) {
    //   let params = JSON.parse(this.$cookies.get('objective_filters'))
    //   this.page = params.page
    //   this.order_by = params.order_by
    //   this.order_direction = params.order_direction
    //   this.search = params.search
    //   this.searchHold = params.searchHold
    //   this.searchType = params.searchType
    //   this.loaded = true
    //   this.setPage(this.page)
    // } else {
    this.loaded = true
    this.setPage(1)
    if (this.inModal) {
      this.makeModalLarge()
    }
    // }
  },
  props: {
    unitName: {
      type: String,
      default() {
        return null
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return true
      }
    },
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    objective: {
      type: Object,
      default() {
        return {
          name: '', reference: ''
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return true
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

