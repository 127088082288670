<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Topic</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Topic">
          <b-input expanded v-model="topicObject.name" required placeholder="Topic name"></b-input>
        </b-field>
        <b-field expanded label="Min %">
          <b-input expanded placeholder="10" type="number" v-model="topicObject.min_percent"></b-input>
        </b-field>
        <b-field label="Max %">
          <b-input placeholder="15" type="number" v-model="topicObject.max_percent"></b-input>
        </b-field>

        <b-field label="Min Hours">
          <b-input placeholder="18" type="number" v-model="topicObject.min_hours"></b-input>
        </b-field>
        <b-field label="Max Hours">
          <b-input placeholder="20" type="number" v-model="topicObject.max_hours"></b-input>
        </b-field>

        <b-field label="Order">
          <b-input placeholder="5" required type="number" min="0" v-model="topicObject.order"></b-input>
        </b-field>
        <b-field label="Topic Reference">
          <b-input placeholder="E1" required v-model="topicObject.reference"></b-input>
        </b-field>
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-field v-if="!inModal" class="has-text-centered">
          <b-button native-type="submit" type="is-primary">Save</b-button>
        </b-field>
      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>


import Topic from "@/models/Topic";

export default {
  name: "TopicCreateForm",
  data() {
    return {
      isLoading: false,
      topicObject: {
        name: null,
        min_percent: null,
        max_percent: null,
        order: null,
        min_hours: null,
        max_hours: null,
        reference: null,
        course_scope_id: this.course_scope_id
      },
    }
  },
  methods: {
    submit() {
      this.isLoading = true
      Topic.Store(this.topicObject).then(() => {
        this.isLoading = false
        this.$buefy.snackbar.open('Topic Created!')
        this.$emit('close')
      }).catch(err => {
        this.isLoading = false
        this.handleError(err)
      })
    },
  },

  props: {

    course_scope_id: {
      type: Number,
      required: true
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

