<template>
  <Course :id="parseInt(id)"></Course>
</template>
<script>

import Course from "@/components/courses/Course";

export default {
  props: ['id'],
  name: 'CourseEdit',
  components: {Course},
}
</script>
