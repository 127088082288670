<template>
  <div v-if="loaded">
    <CampusHeader
        :show-head="true"
        :show-campus="false"
        :can-create="false"
        :activeTab="activeTab"
        :manage-campuses="false"
        :can-select="false"
        @close="close"
        @change-tab="changeTab"
        :header-text="headerText"
        :hide-tabs="true"
        :close-button="inModal"
        :header_tabs="tabs"
    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  :destroy-on-hide="true" @input="changeTab" :class="{'hidden-tabs':tabs.length<2}" v-model="activeTab">
        <b-tab-item v-if="useTab('scope')" label="Course Scope">
          <div class=" columns">
            <div class="column">
              <CourseScopeForm :campus_id="$store.state.campus.selected_campus_id" :submit-bit-flip="scopeSubmitBitFlip"
                               :in-modal="inModal" :edit="true"
                               :course="course"></CourseScopeForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('topics')" label="Topics">
          <div class=" columns">
            <div class="column">
              <TopicPanel @manage-units="manageUnits" :course_id="course.id"></TopicPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('units')" label="Units">
          <div class=" columns">
            <div class="column">
              <UnitPanel :activeTopicId="topicUnitsManaged" @manage-units="manageUnits"
                         :course_id="course.id"></UnitPanel>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>


</template>

<script>

import CampusHeader from "@/components/panelled-dash/CampusHeader";
import CourseScopeForm from "@/components/courses/CourseScopeForm";
import CourseScope from "@/models/CourseScope";
import TopicPanel from "@/components/courses/TopicPanel";
import UnitPanel from "@/components/courses/UnitPanel";

export default {
  name: 'WholeCourse',
  components: {CampusHeader, CourseScopeForm, TopicPanel, UnitPanel},
  data() {
    return {loaded: false, activeTab: 0, scopeSubmitBitFlip: 0, topicUnitsManaged: null}
  },
  props: {
    inModal: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: Number,
      required: true
    },
    tabs: {
      type: Array, default() {
        return [{title: 'Scope', role: 'scope'}, {title: 'Topics', role: 'topics'}, {title: 'Units', role: 'units'}]
      }
    }
  },
  computed: {
    headerText() {
      return `Edit Course`;
    },
    course() {
      return CourseScope.query().whereId(this.id).first()
    }
  }, methods: {
    manageUnits(topic) {
      this.activeTab = 2
      this.topicUnitsManaged = topic.id
      this.setQuery({tab: 'units', topic_id: topic.id})
    },
    close() {
      this.$emit('close')
    },
    changeTab(index) {
      this.activeTab = index;
      this.setQuery({tab: this.tabs[index].role})
    },
    goToTabName(tabName) {
      this.activeTab = this.tabs.findIndex(tab => {
        return tab.role === tabName
      })

      this.setQuery({tab: tabName})

    },
    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },
  },
  mounted() {
    this.$store.dispatch('loader/show')
    Promise.all([CourseScope.FetchById(this.id, ['stages'])]).then(() => {
      if (this.$route.query.tab) {
        if (this.useTab(this.$route.query.tab)) {
          if (this.$route.query.tab === 'units') {
            if (this.$route.query.topic_id) {
              this.manageUnits({id: parseInt(this.$route.query.topic_id)})

            }
          } else {
            this.goToTabName(this.$route.query.tab)

          }

        }
      }
      this.loaded = true
    })
    this.$store.dispatch('loader/hide')

  }
}
</script>
