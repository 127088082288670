<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Unit</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Name">
          <b-input expanded v-model="unitObject.name" required placeholder="Unit name"></b-input>
        </b-field>
        <b-field label="Order">
          <b-input type="number" min="0" v-model="unitObject.order" placeholder="5" required></b-input>
        </b-field>
        <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
        <b-field v-if="!inModal" class="has-text-centered">
          <b-button native-type="submit" type="is-primary">Save</b-button>
        </b-field>
      </section>
      <footer v-if="inModal" :class="{'modal-card-foot':inModal}">
        <button class="button" type="is-primary" :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
    </div>
  </form>
</template>

<script>


import Unit from "@/models/Unit";

export default {
  name: "UnitCreateForm",
  data() {
    return {
      isLoading: false,
      unitObject: {
        name: null,
        course_scope_topic_id: this.course_scope_topic_id,
        order: 0
      },
    }
  },
  methods: {
    submit() {
      this.isLoading = true

      if (this.edit) {
        delete this.unitObject.objectives
        Unit.Update(this.unitObject, ['objectives']).then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open('Unit Updated!')
          this.$emit('close')
        }).catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
      } else {
        Unit.Store(this.unitObject).then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open('Unit Created!')
          this.$emit('close')
        }).catch(err => {
          this.isLoading = false
          this.handleError(err)
        })
      }
    },
  },
  mounted() {
    if (this.edit) {
      this.unitObject = this.unit
    }
  },
  props: {
    unit: {
      type: Object,
      default() {
        return null
      }
    },
    course_scope_topic_id: {
      type: Number,
      required: true
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

