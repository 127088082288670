<template>
  <div>
    <div class="level" @click.self="isHidden">
      <!-- Left side -->
      <div class="level-left" @click="isHidden">
        <div class="level-item">
          <p><span class="is-size-3">{{ unit.name }}</span>
            <span class="is-size-4"> - {{ unit.order }}</span></p>
        </div>

      </div>
      <div class="level-right">
        <div class="level-item">
          <b-field grouped>
            <b-field>
              <b-button
                  :icon-right="$tc('icons.delete')"
                  type="is-ghost"
                  class="has-text-danger"
                  @click="startDeleteUnit"
              >

              </b-button>
            </b-field>
            <b-field>
              <b-button
                  :icon-right="$tc('icons.edit')"
                  type="is-primary"
                  @click="startEditUnit"
              >

              </b-button>
            </b-field>

            <b-field>
              <b-button
                  :icon-right="$tc('icons.create')"
                  type="is-primary"
                  @click="startObjectiveAdd"
              >
                Add Objective
              </b-button>
            </b-field>

            <b-field>
              <a
                  class="card-header-icon has-text-link px-0 py-0"
                  @click="isHidden"
              >
                <b-icon
                    :icon="hidden?'menu-down':'menu-up'" size="is-medium"/>
              </a>
            </b-field>

          </b-field>
        </div>
      </div>
      <!-- Right side -->
    </div>
    <b-table
        :bordered="true"
        :class="{'is-hidden': hidden}"
        :data="unit.objectives"
        :hoverable="true"
        :striped="true"
        class="margin-top"
    >
      <template #empty>
        <div class="has-text-centered">No Objectives</div>
      </template>
      <b-table-column
          v-slot="props"
          field="id"
          label="ID"
          numeric
          sortable
          width="40"
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="reference"
          label="Objective Reference"
          sortable
      >{{ props.row.reference }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="name"
          label="Content"
          sortable
      ><span v-html="props.row.name"></span>
      </b-table-column>

      <b-table-column
          v-slot="props"
          centered
          custom-key="actions"
          label="Actions"

      >
        <b-dropdown append-to-body
                    aria-role="list" position="is-bottom-left">
          <template #trigger="{ active }">
            <b-button
                :icon-right="active ? 'menu-up' : 'menu-down'"
                label="Actions"
                type="is-primary"/>
          </template>

          <b-dropdown-item aria-role="listitem" @click="editObjective(props.row)">
            View
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="removeObjective(props.row)">
            Remove
          </b-dropdown-item>

        </b-dropdown>
      </b-table-column>

    </b-table>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>

import ObjectiveForm from "@/components/objectives/ObjectiveForm";
import Unit from "@/models/Unit";
import ObjectiveSearch from "@/components/objectives/ObjectiveSearch";
import UnitObjective from "@/models/UnitObjective";

export default {
  name: "UnitItem",
  props: {
    unit: {type: Object, required: true}
  },
  data() {
    return {
      isLoading: false,
      hidden: true,
    }
  },
  methods: {
    isHidden() {
      this.hidden = !this.hidden
    },
    startEditUnit() {
      this.$emit('edit')
    },
    startDeleteUnit() {
      this.$buefy.dialog.confirm({
        title: `Deleting unit`,
        confirmText: `Delete unit`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this unit?`,
        onConfirm: () =>
            Unit.Delete(this.unit.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Unit deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
      startObjectiveAdd() {
        this.$buefy.modal.open({
          parent: this,
          props: {
            unitName: this.unit.name,
            inModal: true
          },
          component: ObjectiveSearch,
          hasModalCard: false,
          trapFocus: true,
          events: {
          "selected": (objective) => {
            this.isLoading = true
            let validObjectives = this.unit.objectives
            validObjectives.push(objective)
            let updateObject = this.unit
            let validObjectivesIds = []
            validObjectives.map(item => validObjectivesIds.push(item.id))
            updateObject.objectives = validObjectivesIds
            Unit.Update(updateObject, ['objectives'])
                .then(() => {
                  this.isLoading = false
                  this.$buefy.snackbar.open(`Unit updated!`);
                })
                .catch((err) => {
                  this.isLoading = false
                  this.handleError(err)
                })
          },
        },
      });
    },
    removeObjective(objective) {
      this.isLoading = true
      let validObjectives = this.unit.objectives.filter(item => item.id !== objective.id)
      let updateObject = JSON.parse(JSON.stringify(this.unit))
      let validObjectivesIds = []
      validObjectives.map(item => validObjectivesIds.push(item.id))
      updateObject.objectives = validObjectivesIds
      Unit.Update(updateObject, ['objectives'])
          .then(() => {
            UnitObjective.delete([objective.id, this.unit.id])
            this.isLoading = false
            this.$buefy.snackbar.open(`Unit updated!`);
          })
          .catch((err) => {
            this.isLoading = false
            this.handleError(err)
          })
    },
    editObjective(objective) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          objective: objective,
          canEdit: !!this.$store.getters['entities/user-permissions/find']('edit objectives')

        },
        component: ObjectiveForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },

    startRemove(objective) {
      this.$buefy.dialog.confirm({
        title: `Removing objective`,
        confirmText: `Remove objective`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to remove this objective?`,
        onConfirm: () =>
            this.removeObjective(objective),
      });
    },
  }
}

</script>
